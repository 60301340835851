/*
These functions make sure WordPress
and Foundation play nice together.
*/

// cookie creation/deletion
function createCookie(name, value, days) {
	var expires;

	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = "; expires=" + date.toGMTString();
	} else {
		expires = "";
	}
	document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
}

function readCookie(name) {
	var nameEQ = encodeURIComponent(name) + "=";
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ')
			c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) === 0)
			return decodeURIComponent(c.substring(nameEQ.length, c.length));
	}
	return null;
}

function eraseCookie(name) {
	createCookie(name, "", -1);
}

function url_has_vars() {
	if(window.location.pathname === '/'){
		return false;
	} else {
		return true;
	}
}

jQuery(document).ready(function() {

	// Remove empty P tags created by WP inside of Accordion and Orbit
	jQuery('.accordion p:empty, .orbit p:empty').remove();

	// Adds Flex Video to YouTube and Vimeo Embeds
	jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
		if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
		  jQuery(this).wrap("<div class='widescreen responsive-embed'/>");
		} else {
		  jQuery(this).wrap("<div class='responsive-embed'/>");
		}
	});

	jQuery('.large-block-carousel').slick({
		dots: false,
		arrows: true,
		autoplay: true,
  		autoplaySpeed: 3000,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

	jQuery(".listing-map-marker").click(function () {
		var markerButton = jQuery('.button', this),
			markerModalID = markerButton.data('open'),
			markerModal = jQuery('#' + markerModalID + ' .responsive-embed'),
			markerMapUrl = markerModal.attr('data-mapurl'),
			markerIframe = jQuery('#' + markerModalID + ' .responsive-embed iframe' );
			
			markerIframe.attr('src', markerMapUrl);
	});

	
	jQuery("#off-canvas-close").click(function () {
		jQuery('#off-canvas-footer').foundation('close');
		eraseCookie('footer-offc');
		createCookie('footer-offc', 'hide', 31);
	});

	if (readCookie('footer-offc') == 'show' ){
		jQuery('#off-canvas-footer').foundation('open');
	} else if (readCookie('footer-offc') == 'hide') {
		jQuery('#off-canvas-footer').foundation('close');
	} else {
		if (url_has_vars() == 1) {
			jQuery(window).scroll(function (e) {
				var scrollTop = jQuery(window).scrollTop();
				var docHeight = jQuery(document).height();
				var winHeight = jQuery(window).height();
				var scrollPercent = scrollTop / (docHeight - winHeight);
				var scrollPercentRounded = Math.round(scrollPercent * 100);

				if( scrollPercentRounded >= '33' ){
					if (readCookie('footer-offc') != 'show') {
						jQuery('#off-canvas-footer').foundation('open');
						createCookie('footer-offc', 'show', 31);
						jQuery(window).off('scroll');
					}
				}
			});
		}
	}

	jQuery('a[href*="#"]')
		// Remove links that don't actually link to anything
		.not('[href="#"]')
		.not('[href="#0"]')
		.click(function (event) {
			// On-page links
			if (
				location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
				&&
				location.hostname == this.hostname
			) {
				// Figure out element to scroll to
				var target = jQuery(this.hash);
				target = target.length ? target : jQuery('[name=' + this.hash.slice(1) + ']');
				// Does a scroll target exist?
				if (target.length) {
					// Only prevent default if animation is actually gonna happen
					event.preventDefault();
					jQuery('html, body').animate({
						scrollTop: target.offset().top
					}, 1000, function () {
						// Callback after animation
						// Must change focus!
						var $target = jQuery(target);
						$target.focus();
						if ($target.is(":focus")) { // Checking if the target was focused
							return false;
						} else {
							$target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
							$target.focus(); // Set focus again
						};
					});
				}
			}
		});

});
